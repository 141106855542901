<script>
import {useStore} from "vuex";
import SvgIcon from '@/components/SvgIcon';
export default {
  props : ['country', 'items', 'indices'],
  setup(){
    // console.log(props);
    const store = useStore();
    return {
      getIcon : store.getters.getIcon,
    }
  },
  components : {
    SvgIcon
  }
}
</script>

<template>
<div class="col-12 pt-4 mt-4">
        <h2><strong>OBJETIVO 4</strong>: 90% de las personas tienen acceso a la prevención combinada del VIH y servicios de salud sexual y reproductiva</h2>
        <div class="table">
        <table class="table objetivos">
          <tbody>
            <tr>
              <td rowspan="2">
                <div class="row mb-4">
                  <div class="col-2">
                    <svg-icon :icon="getIcon('gasto')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-10">
                    90% de las mujeres y los hombres, especialmente los jóvenes y los que están en entornos de alta prevalencia, tienen acceso a la prevención combinada del VIH y servicios de salud sexual y reproductiva.
                  </div>
                </div>                
              </td>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('personas')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Compromiso 3 de la Reunión de Alto Nivel (High Level Meeting) 2016-2021 de la ONU</strong><br>
                  Garantizar el acceso a los programas combinados de prevención, incluyendo la profilaxis pre-exposición, la circuncisión médica masculina voluntaria, la reducción de daños y los preservativos a, al menos, el 90 % de la población antes de 2020, especialmente las mujeres jóvenes y las adolescentes en países de alta prevalencia y las poblaciones clave: hombres que tienen sexo con hombres, personas trans, personas trabajadoras sexuales, personas usuarias de drogas y personas privadas de libertad.
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-1">
                    <svg-icon :icon="getIcon('tratamiento')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Objetivo de Desarrollo Sostenible  3</strong><br>
                  Buena salud y bienestar
                  </div>
                  <div class="col-1">
                    <svg-icon :icon="getIcon('tratamiento')" :hasFill="true" class="od_svg_icon icon_title"></svg-icon>
                  </div>
                  <div class="col-11">
                  <strong>Objetivo de Desarrollo Sostenible  10</strong><br>
                  Reducción de las desigualdades.
                  </div>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
    </div>

</template>