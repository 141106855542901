<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {computed} from "vue";
import {useStore} from "vuex";
import Objective4 from '@/components/Objective4';
import StarGraph from "@/components/StarGraph";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country', 'items', 'indices'],
  setup(props){
    const currentYear =  process.env.VUE_APP_YEAR || 2020;
    const store    = useStore();
    const values   = store.getters.getDataForObjectiveGraphs(props.indices, props.items, 4)
    const textIndi = computed( () => store.getters.getIndicatorText(1,4,props.country.id));
    const all      = store.state.indexGuide.filter(d => d.axis == 1 && d.objective == 4);
    const vector   = computed( () => {
      const res = store.getters.getVector(currentYear, props.country.id);
      if(!res) return null;
      return res.filter(d => d['gasto'] && d['incidencia']);
    });

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      getIcon : store.getters.getIcon,
      values,
      textIndi, 
      all,
      vector
    }
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
    Objective4,
    StarGraph
  }
}
</script>


<template>
  <div class="row">
    <objective-4/>

    <div class="col-sm-10 offset-sm-1 mb-4 pb-4">        
      <div v-html="textIndi"></div>
    </div>
    
    <div class="col-12">
      <div class="row" v-if="vector && vector.length">
        <div class="col-sm-3 offset-sm-2">
          <p class="sg_legend">Tasa de cambio anualizada de incidencia de VIH por cada 100,000 personas (%)</p>
        </div>
        <div class="col-sm-5">
          <ul class="sg_legend_list color_countries">
            <li><span :class="country.id != 'COL' ? country.id.toLowerCase() : 'colm'"></span>{{country.name}}</li>
          </ul>
        </div>
      
        <div class="col-sm-8 offset-sm-2">
          <star-graph :vectors="vector" />
        </div>

        <div class="col-sm-8 offset-sm-2">
          <p class="text-center sg_legend">Tasa de cambio anualizada de gasto per capita en  VIH  (%)</p>
        </div>
      </div>

      <div v-else class="row">
        <div class="col-sm-10 offset-sm-1">
          <h4>Tasa de cambio anualizada de incidencia de VIH por cada 100,000 personas y tasa de cambio anualizada de gasto per capita en  VIH no disponibles   en <strong>{{country.name}}</strong></h4>
        </div>
        
        <div class="col-sm-10 offset-sm-1 mt-4">
            <div class="mb-3">
              <span style="display: inline-block; height: 15px; width: 100%; background: rgb(242, 242, 240);"></span>
              <div class="od_triangle_danger"></div>
            </div>
         </div>
       
      </div>
    </div>

    
  </div>
</template>